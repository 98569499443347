<template>
  <action-card-base
    v-if="actionData"
    :title="actionData.titre"
    :text="actionData.texte"
    :cover="actionData.cover"
    :link="actionData.lien"
    :backgroundColor="backgroundColor"
    :textColor="textColor"
    :arrowColor="arrowColor"
    :buttonColor="buttonColor"
    @click="emits('click')"
    ></action-card-base>
  <!--nuxt-link  v-if="actionData" :to="actionData.lien" class="action-card" target="_blank" @click="emits('click')">
    <div class="action-card__image">
      <img :src="actionData.cover">
    </div>
    <div class="action-card__content" :style="{
      backgroundColor: backgroundColor || undefined,
      color: textColor || undefined
    }">
      <mks-text tag="h4" class="action-card__title">{{ actionData.titre }}</mks-text>
      <mks-text tag="p" class="action-card__text">{{ actionData.texte }}</mks-text>
      <mks-button class="action-card__button" size="small" :style="{
        borderColor: buttonColor || undefined,
        backgroundColor: buttonColor || undefined,
        color: arrowColor || undefined
      }">
        <long-arrow width="35" />
      </mks-button>
    </div>
  </nuxt-link-->
</template>

<script setup>
import ActionCardBase from './ActionCardBase.vue';

const props = defineProps({
  action: Object,
  backgroundColor: String,
  textColor: String,
  buttonColor: String,
  arrowColor: String
});
const actionData = computed(() => {
  if (props?.action?.value?.data) {
    return props.action.value.data;
  }
  else if (props?.action?.data) {
    return props.action.data;
  }
  return null;
})


const emits = defineEmits(['click'])
</script>
